module.exports = {
  API_HOST: process.env.API_HOST,
  API_OCP_APIM_SUBSCRIPTION_KEY: process.env.API_OCP_APIM_SUBSCRIPTION_KEY,
  APP_BASE_DOMAIN: process.env.APP_BASE_DOMAIN,
  APP_GOOGLE_MAPS_KEY: process.env.APP_GOOGLE_MAPS_KEY,
  APP_MEILISEARCH_HOST: process.env.APP_MEILISEARCH_HOST,
  APP_MEILISEARCH_API_KEY: process.env.APP_MEILISEARCH_API_KEY,
  APP_ENVIRONMENT: process.env.APP_ENVIRONMENT,
  APP_GTM_ID: process.env.APP_GTM_ID,
  API_HOST_STRAPI_GRAPHQL: process.env.API_HOST_STRAPI_GRAPHQL,
  API_GRAPHQL_QUERY_EXT: process.env.API_GRAPHQL_QUERY_EXT,
  APP_AGROANALISIS_FEATURE_FLAG: String(process.env.APP_AGROANALISIS_FEATURE_FLAG) === 'true',
  APP_PESTS_FEATURE_FLAG: String(process.env.APP_PESTS_FEATURE_FLAG) === 'true',
  APP_ANALISIS_FEATURE_FLAG: String(process.env.APP_ANALISIS_FEATURE_FLAG) === 'true',
  APP_FINISHED_CROPS_FLAG: String(process.env.APP_FINISHED_CROPS_FLAG) === 'true',
  APP_HUBSPOT_TRACKING_CODE: process.env.APP_HUBSPOT_TRACKING_CODE,
  APP_MS_CLIENT_ID: process.env.APP_MS_CLIENT_ID,
  APP_MS_AUTHORITY_SIGNIN: process.env.APP_MS_AUTHORITY_SIGNIN,
  APP_MS_AUTHORITY_PASSWORDRESET: process.env.APP_MS_AUTHORITY_PASSWORDRESET,
  APP_MS_KNOWN_AUTHORITIES: process.env.APP_MS_KNOWN_AUTHORITIES,
  APP_MS_REDIRECT_URI: process.env.APP_MS_REDIRECT_URI,
  APP_TOOLS_BASE_URL: process.env.APP_TOOLS_BASE_URL,
  API_HOST_NOTIFICATIONS: process.env.API_HOST_NOTIFICATIONS,
  APP_FORCED_NEW_HYDRIC_BALANCE: String(process.env.APP_FORCED_NEW_HYDRIC_BALANCE) === 'true',
  APP_MS_EXPOSE_API: process.env.APP_MS_EXPOSE_API
};
