module.exports = {
  PROFILE_PAGE: '/perfil',
  SUCCESS_PASSWORD_RESET_URL: '/establecer-contrasena',
  COURSE_BASE_URL: '/formacion',
  OUR_TOOLS_BASE_URL: '/herramientas',
  CXTIERRA_BASE_URL: '/cxtierra',

  EVT_LOAD_DATA: 'loadData',
  EVT_CHANGED_NAVBAR: 'changedNavbar',
  EVT_TOGGLE_SIDEBAR: 'toggleSidebar',
  EVT_CHANGED_LOADING: 'changedLoading',
  EVT_CHANGED_PLANS: 'changedPlans',
  EVT_REFRESH_PLANS: 'refreshPlans',
  EVT_AGRO_ANALYSIS: 'agroAnalysis',
  EVT_AUTH_AUTHENTICATED_USER: 'app:services:auth:authenticated-user',
  EVT_AUTH_USER_CANCELED_ENTRY: 'app:services:auth:user-canceled-entry',

  STATUS_LOADED: 'statusLoaded',
  STATUS_LOADING: 'statusLoading',
  STATUS_NOT_FOUNT: 'statusNotFound',
  STATUS_SERVICE_WORKER: 'statusServiceWorker',

  STORAGE_NAVBAR: 'storageNavbar',
  STORAGE_IRRIGATION_PLAN: 'storageIrrigationPlan',
  STORAGE_HAS_IRRIGATION_PLAN: 'storageHasIrrigationPlan',
  STORAGE_STATUS_FORECAST_IRRIGATION: 'storageStatusForecastIrrigation',
  STORAGE_FORECAST_IRRIGATION: 'storageForecastIrrigation',
  STORAGE_FERTILIZATION_PLAN: 'storageFertilizationPlan',
  STORAGE_STATUS_FORECAST_FERTILIZATION: 'storageStatusForecastFertilization',
  STORAGE_FORECAST_FERTILIZATION: 'storageForecastFertilization',
  STORAGE_MSAL_TOKEN: 'msalToken',
  STORAGE_VERSION_SERVICE_WORKER: 'versionServiceWorker',
  STORAGE_WATER_BALANCE_ENABLED: 'waterBalanceEnabled',

  DEPLOY_DATE_SOIL_BALANCE: '2023-02-17',

  HEADER_ITEMS: [
    {
      label: 'Mercados',
      url: '/mercados'
    },
    {
      label: 'Innovación',
      url: '/innovacion'
    },
    {
      label: 'Actualidad',
      url: '/actualidad'
    },
    {
      label: 'Herramientas',
      url: '/nuestras-herramientas'
    },
    {
      label: 'Formación',
      url: '/formacion'
    },
    {
      label: 'Comunidad',
      url: '/comunidad'
    }
  ],

  NAV_ITEM_DASHBOARD: {
    name: 'sidebar.dashboard.title',
    value: 'home',
    icon: 'pt-dashboard',
    href: '/',
    enable: true
  },
  NAV_ITEM_CXTIERRA: {
    name: 'sidebar.cxTierra.title',
    value: 'cxtierra',
    icon: 'pt-external-link-alt',
    svg: 'cxtierra.svg',
    href: process.env.APP_CXTIERRA_URL,
    enable: true,
    target: '_blank'
  },
  NAV_ITEM_METEO: {
    name: 'sidebar.weather.title',
    value: 'tiempo',
    icon: 'pt-sun-cloud',
    href: '/tiempo',
    enable: true
  },
  NAV_ITEM_FERTILIZATION: {
    name: 'sidebar.fertilization.title',
    value: 'fertilizacion',
    icon: 'pt-fertilization-plan',
    href: '/fertilizacion',
    enable: true
  },
  NAV_ITEM_IRRIGATION: {
    name: 'sidebar.irrigation.title',
    value: 'riego',
    icon: 'pt-kpi-irrigation-and-fertilization',
    href: '/riego',
    enable: true
  },
  NAV_ITEM_PEST: {
    name: 'sidebar.pest.title',
    value: 'plagas',
    icon: 'pt-kpi-pest-control',
    href: '/plagas',
    query: ['explotacionId', 'fincaId', 'cultivoId'],
    enable: true
  },
  NAV_ITEM_AGRO: {
    name: 'sidebar.agro.title',
    value: 'agro',
    icon: 'pt-kpi-agricultural-holdings',
    href: '/agroanalisis',
    query: ['explotacionId', 'fincaId', 'cultivoId'],
    enable: true
  },
  NAV_ITEM_WATER: {
    name: 'sidebar.water.title',
    value: 'agua',
    icon: 'pt-waterglasregular',
    href: '/analisis/agua',
    query: ['explotacionId', 'fincaId'],
    enable: true
  },
  NAV_ITEM_GROUND: {
    name: 'sidebar.ground.title',
    value: 'suelo',
    icon: 'pt-kpi-soil-management',
    href: '/analisis/suelo',
    query: ['explotacionId', 'fincaId'],
    enable: true
  },
  NAV_ITEM_FINISHED_CROPS: {
    name: 'sidebar.finishedCrops.title',
    value: 'finishedcrops',
    icon: 'pt-Harvest',
    href: '/cultivos-finalizados',
    enable: false
  },
  TOOLS_NAME_GTM: {
    METEO: 'Tiempo',
    IRRITATION: 'Riego',
    FERTILIZATION: 'Fertilización'
  },

  ESTATIONS_METEO_CAT: ['CAT01', 'CAT02', 'CAT03', 'CAT04']
};
